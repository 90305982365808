import { Column } from "components/Table/Simple";
import { formatDateTime, formatDollar } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "orderId",
        label: "주문ID",
        visible: true,
    },
    {
        field: "user",
        label: "사용자",
        visible: true,
        render: (value, field, row) => {
            return row.user?.name
        }
    },
    {
        field: "status",
        label: "상태",
        visible: true,
    },
    {
        field: "items",
        label: "아이템",
        visible: false,
    },
    {
        field: "totalPrice",
        label: "총 가격",
        visible: true,
        render: formatDollar,
        align: "right"
    },
    {
        field: "createdAt",
        label: "생성일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "updatedAt",
        label: "수정일",
        visible: true,
        render: formatDateTime
    }
]