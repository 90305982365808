import React from "react"
import { classNames } from "components/uiUtils"

const IconButton = ({ onButtonClicked = () => { }, children, paddingClassName = "px-2 py-2", disabled = false, ...otherProps }) => {
    return (
        <button
            type="submit"
            className={classNames(
                "inline-flex w-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-500",
                disabled ? "bg-gray-200 cursor-not-allowed" : "bg-white hover:bg-gray-50",
                paddingClassName,
                otherProps.classNames
            )}
            onClick={onButtonClicked}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export default IconButton;