import React, { useState, cloneElement, useRef } from 'react';
import Modal from 'react-modal';

export const defaultStyles: React.CSSProperties | any = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 120,
    },
    content: { // contents to center
        position: 'static',
        margin: '0 auto',
        marginTop: '10vh',
        width: "fit-content",
        height: "fit-content",
        maxWidth: "90vw",
        maxHeight: "75vh",
        overflow: 'scroll',
    },
}

export const useModal = ({ styles = defaultStyles } = {}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [externalData, setExternalData] = useState<any>(null);
    const resolveRef = useRef<(value: any) => void | null>(null);

    function closeModal() {
        console.log("Close");
        setIsOpen(false);
        if (resolveRef.current) {
            resolveRef.current(null);
        }
    }

    function openModal(externalData?: any) {
        console.log("Open", externalData);
        setExternalData(externalData);
        setIsOpen(true);
    }

    function openModalSync(externalData?: any) {
        console.log("Open Sync", externalData);
        setExternalData(externalData);
        setIsOpen(true);
        return new Promise<any>((resolve) => {
            resolveRef.current = resolve;
        });
    }

    return {
        Modal: ({ children, parentSelector = () => document.body }) =>
        (
            <div>
                <Modal
                    isOpen={isOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={{ overlay: { ...defaultStyles.overlay, ...styles.overlay }, content: { ...defaultStyles.content, ...styles.content } }}
                    contentLabel="Example Modal"
                    key="simpleModal"
                    ariaHideApp={false}
                    parentSelector={parentSelector}
                >
                    {cloneElement(children, { externalData, closeModal, resolveRef })}
                </Modal>
            </div>
        ),
        closeModal,
        openModal,
        openModalSync,
        isOpen
    }
}

export default { useModal };