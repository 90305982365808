import { classNames } from 'components/uiUtils'
import React from 'react'

export default ({ label = "", placeholder = "", value, onChange = (text) => { }, rows = null, resizable = false, disabled = false }) => {
    return (
        <div>
            <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                {label}
            </label>
            <div className="">
                <textarea
                    rows={rows ?? value?.split('\n')?.length ?? 1}
                    name="comment"
                    id="comment"
                    className={classNames("block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        resizable ? "" : "resize-none",
                        disabled ? "bg-gray-100" : ""
                    )}
                    onChange={(e) => { onChange(e.target.value) }}
                    placeholder={placeholder}
                    value={value ?? ""}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}
