import React from "react"
import SideNav from "components/SideNav"

const menus = [
    // {
    //     title: "Main",
    //     subMenus: [
    //         {
    //             title: "Home",
    //             to: "/main/home",
    //         },
    //     ],
    // },
    // {
    //     title: "Shop",
    //     subMenus: [
    //         {
    //             title: "Lists",
    //             to: "/shop/lists",
    //         },
    //         // {
    //         //     title: "Lists",
    //         //     to: "/product/lists",
    //         // },
    //         // {
    //         //     title: "Overviews",
    //         //     to: "/product/overviews",
    //         // },
    //         // {
    //         //     title: "Features",
    //         //     to: "/product/features",
    //         // },
    //     ],
    // },
    // {
    //     title: "Category",
    //     subMenus: [
    //         {
    //             title: "Lists",
    //             to: "/category/lists",
    //         },
    //         // {
    //         //     title: "Lists",
    //         //     to: "/category/lists",
    //         // },
    //     ],
    // },
    // {
    //     title: "Product",
    //     subMenus: [
    //         {
    //             title: "Lists",
    //             to: "/product/lists",
    //         },
    //         {
    //             title: "Editor",
    //             to: "/product/editor",
    //         },
    //         // {
    //         //     title: "Overviews",
    //         //     to: "/product/overviews",
    //         // },
    //         // {
    //         //     title: "Features",
    //         //     to: "/product/features",
    //         // },
    //     ],
    // },
    // {
    //     title: "Order",
    //     subMenus: [
    //         {
    //             title: "Shopping Cart",
    //             to: "/order/shopping-cart",
    //         },
    //         {
    //             title: "Checkout",
    //             to: "/order/checkout",
    //         },
    //         {
    //             title: "History",
    //             to: "/order/history",
    //         },
    //         {
    //             title: "Summaries",
    //             to: "/order/summaries",
    //         },
    //     ],
    // }
    {
        title: "판매자 관리",
        subMenus: [
            {
                title: "기본정보 관리",
                to: "/seller/profile",
            },
            {
                title: "매니저 관리",
                to: "/seller/manager",
            },
            {
                title: "SNS 설정",
                to: "/seller/sns",
            },
            {
                title: "정보변경 신청",
                to: "/seller/change",
            }
        ],
    },
    {
        title: "상품관리",
        subMenus: [
            {
                title: "상품목록",
                to: "/product/lists",
            },
            {
                title: "상품등록",
                to: "/product/editor",
            },
        ],
    },
    {
        title: "주문관리",
        subMenus: [
            {
                title: "주문내역",
                to: "/order/lists",
            },
            {
                title: "주문처리",
                to: "/order/process",
            },
            {
                title: "배송관리",
                to: "/order/delivery",
            },
            {
                title: "구매확정관리",
                to: "/order/confirm",
            },
            {
                title: "취소관리",
                to: "/order/cancel",
            },
            {
                title: "반품관리",
                to: "/order/return",
            },
            {
                title: "교환관리",
                to: "/order/exchange",
            }
        ],
    },
    {
        title: "정산관리",
        subMenus: [
            {
                title: "정산내역",
                to: "/settlement/lists",
            },
            {
                title: "정산현황",
                to: "/settlement/status",
            },
            {
                title: "출금요청",
                to: "/settlement/withdraw",
            }
        ],
    },
    {
        title: "리뷰관리",
        subMenus: [
            {
                title: "리뷰목록",
                to: "/review/lists",
            },
            {
                title: "신고내역",
                to: "/review/report",
            }
        ],
    },
    {
        title: "프로모션",
        subMenus: [
            {
                title: "공지사항",
                to: "/promotion/notice",
            },
            {
                title: "이벤트",
                to: "/promotion/event",
            },
            {
                title: "쿠폰",
                to: "/promotion/coupon",
            }
        ],
    },
    {
        title: "고객관리",
        subMenus: [
            {
                title: "1:1문의",
                to: "/customer/inquiry",
            },
            {
                title: "FAQ",
                to: "/customer/faq",
            },
        ],
    }
]

export default () => {
    return (
        <SideNav menus={menus}></SideNav>
    )
}