import Card from 'components/Card';
import React, { useEffect, useRef, useState } from 'react';
import PageTitle from 'components/Title/Page';
import QuillEditorWithImageUploader from 'components/Form/Editor/Quill/WithImageUploader';
import Input, { InputFormat } from 'components/Form/Input';
import InputUpperLabel from 'components/Form/Input/Label/InputUpper';
import FileInput from 'components/Form/Input/File';
import axios from 'axios';
import _, { set } from 'lodash';
import Button from 'components/Form/Button';
import { uploadCdnImg } from 'utils/file';
import { MinusCircleIcon, PhotoIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import HorizontalDragList from 'components/Drag/HorizontalList';
import ImgDropper from 'components/Form/Input/ImgDropper';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { infoService } from 'services/info';
import Select from 'components/Form/Select';
import SpinnerMedium from 'components/Spinner/Medium';

const ProductEditor = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const [imgUrls, setImgUrls] = useState([]);
    const quillRef = useRef(null);
    const [formData, setFormData] = useState({
        productName: "",
        productDescription: "",
        price: "",
    });
    const [product, setProduct] = useState(null);
    const [sellerItems, setSellerItems] = useState(null);
    const [selectedSellerItem, setSelectedSellerItem] = useState(null);
    const [categoryItems, setCategoryItems] = useState(null);
    const [selectedCategoryItem, setSelectedCategoryItem] = useState(null);

    useEffect(() => {
        console.log("[useEffect] []");
        // Seller
        const sellers = infoService.getSellers();
        console.log("[useEffect] sellers", sellers);
        const tempSellerItems = sellers.map((seller) => ({ data: seller, name: seller.name }));
        console.log("[useEffect] tempSellerItems", tempSellerItems);
        setSellerItems(tempSellerItems);
        setSelectedSellerItem(tempSellerItems[0]);
        // Category
        const categories = infoService.getCategories();
        console.log("[useEffect] categories", categories);
        const tempCategoryItems = categories.map((category) => ({ data: category, name: category.name }));
        console.log("[useEffect] tempCategoryItems", tempCategoryItems);
        setCategoryItems(tempCategoryItems);
        setSelectedCategoryItem(tempCategoryItems[0]);
    }, [])

    useEffect(() => {
        console.log("[useEffect] product_id", productId);
        if (_.isNil(productId)) {
            return;
        }
        fetchData();
    }, [productId]);

    useEffect(()=>{
        if(_.isNil(product)){
            return;
        }
        const newFormData = {
            productName: product.name,
            productDescription: product.description,
            price: product.price,
        }
        setFormData(newFormData);
        const category = categoryItems.find((item) => item.data.categoryId === product.category.categoryId);
        console.log("[useEffect] category", category);
        setSelectedCategoryItem(category);
        const seller = sellerItems.find((item) => item.data.sellerId === product.seller.sellerId);
        console.log("[useEffect] seller", seller);
        setSelectedSellerItem(seller);
    },[product])

    const fetchData = async () => {
        try {
            const ret = await axios.get(`https://rest.39f.jp/products/${productId}`);
            console.log("[fetchData] ret", ret);
            const productData = ret?.data?.data;
            console.log("[fetchData] productData", productData);
            setProduct(productData);
            const newImgUrls = [];
            if (!_.isNil(productData.mainImage)) {
                newImgUrls.push(productData.mainImage);
            }
            if (!_.isNil(productData.images)) {
                newImgUrls.push(...productData.images.map((img) => img.url));
            }
            console.log("[fetchData] newImgUrls", newImgUrls);
            setImgUrls(newImgUrls);
            const detailContents = productData?.detail?.quillOps;
            if (_.isNil(quillRef.current)) {
                alert('Quill is not ready');
                return;
            }
            quillRef.current.setContents(detailContents);
        } catch (e) {

        }
    }

    const onFilesSelected = async (files) => {
        console.log("[onFilesSelected] files", files);
        let urls = [];
        if (files.length > 0) {
            for await (const file of files) {
                const url = await uploadCdnImg(file);
                urls.push(url);
            }
        }
        console.log("!!!!! URLS", urls)
        setImgUrls([...imgUrls, ...urls]);
    }

    const onDelete = async () => {
        const ok = window.confirm('Are you sure to delete this product?');
        if (!ok) {
            return;
        }
        try {
            const ret = await axios.delete(`https://rest.39f.jp/products/${productId}`);
            console.log("[onDelete] ret", ret);
            toast.success('Product deleted successfully');
            navigate(-1);
        } catch (e) {
            console.log("[onDelete] error", e);
            toast.error('Failed to delete product');
        }
    }

    const onCreate = async () => {
        console.log("[onCreate] formData", formData, quillRef.current.getContents());
        const detailContents = quillRef.current.getContents();
        const mainImg = imgUrls[0];
        const subImgUrls = imgUrls.slice(1);
        const body = {
            sellerId: Number(selectedSellerItem.data.sellerId),
            categoryId: Number(selectedCategoryItem.data.categoryId),
            name: formData.productName,
            description: formData.productDescription,
            detail: { quillOps: detailContents.ops },
            price: Number(formData.price),
            mainImage: mainImg,
            images: subImgUrls,
        }
        console.log("[onCreate] body", body);
        try {
            const ret = await axios.post('https://rest.39f.jp/products', body);
            console.log("[onCreate] ret", ret);
            toast.success('Product saved successfully');
            navigate("/product/lists");
        } catch (e) {
            console.log("[onCreate] error", e);
            toast.error('Failed to save product');
        }
    }

    const onSave = async () => {
        console.log("[onSave] formData", formData, quillRef.current.getContents());
        const detailContents = quillRef.current.getContents();
        const mainImgUrl = imgUrls[0];
        const subImgUrls = imgUrls.slice(1);
        const body = {
            sellerId: Number(selectedSellerItem.data.sellerId),
            categoryId: Number(selectedCategoryItem.data.categoryId),
            name: formData.productName,
            description: formData.productDescription,
            detail: { quillOps: detailContents.ops },
            price: Number(formData.price),
            mainImage: mainImgUrl,
            images: subImgUrls,
        }
        console.log("[onSave] body", body);
        try {
            const ret = await axios.put(`https://rest.39f.jp/products/${productId}`, body);
            console.log("[onSave] ret", ret);
            toast.success('Product saved successfully');
        } catch (e) {
            console.log("[onSave] error", e);
            toast.error('Failed to save product');
        }
    }

    return (
        <Card>
            <div className="p-3">
                <PageTitle>Product Editor</PageTitle>
                <Card paddingClassNames='p-0 mb-2'>
                    <div className="flex flex-col space-y-1 p-2">
                        <Select upperLabel='Seller'
                            items={sellerItems}
                            selectedItem={selectedSellerItem}
                            onChange={(item) => {
                                setSelectedSellerItem(item);
                            }}
                        />
                        <Select upperLabel='Category'
                            items={categoryItems}
                            selectedItem={selectedCategoryItem}
                            onChange={(item) => {
                                setSelectedCategoryItem(item);
                            }}
                        />
                        <Input upperLabel='Product Name' placeholder='Product Name'
                            onChange={(value) => { setFormData({ ...formData, productName: value }); }}
                            value={formData.productName}
                        />
                        <Input upperLabel='Product Description' placeholder='Product Description'
                            onChange={(value) => { setFormData({ ...formData, productDescription: value }); }}
                            value={formData.productDescription}
                        />
                        <Input upperLabel='Price' format={InputFormat.NUMBER} placeholder='Price'
                            onChange={(value) => { setFormData({ ...formData, price: value }); }}
                            value={formData.price}
                        />
                        <InputUpperLabel>Images</InputUpperLabel>
                        {/* <FileInput onFilesSelected={onMainImgSelected}></FileInput> */}
                        <Card paddingClassNames='p-0'>
                            <div className="overflow-auto">
                                <div className="flex justify-center space-x-2 p-2 min-w-max">
                                    {imgUrls.map((url, index) => (
                                        <div className="relative border-solid border-2 border-indigo-600 rounded-md" key={index}>
                                            <div className="w-40 h-40 overflow-scroll">
                                                <span className="absolute top-1 right-1">
                                                    <MinusCircleIcon
                                                        className="size-6 cursor-pointer text-indigo-600"
                                                        onClick={() => {
                                                            setImgUrls(imgUrls.filter((_, i) => i !== index));
                                                        }}
                                                    />
                                                </span>
                                                <img key={index} src={url} className="rounded-md" />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="w-40 h-40">
                                        <ImgDropper onFilesSelected={onFilesSelected}></ImgDropper>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div>
                            <InputUpperLabel>Product Detail</InputUpperLabel>
                            <QuillEditorWithImageUploader placeholder=' ' onQuillReady={(quill) => { quillRef.current = quill }} />
                        </div>
                    </div>
                </Card>
                <div className="flex justify-between space-x-2">
                    <div>
                        <div>
                            <Button onButtonClicked={() => { navigate(-1) }}>Back</Button>
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div>
                            <Button onButtonClicked={onDelete} disabled={_.isNil(productId)}>Delete</Button>
                        </div>
                        <div>{_.isNil(productId) ? (
                            <Button onButtonClicked={onCreate}>Create</Button>
                        ) : (
                            <Button onButtonClicked={onSave}>Save</Button>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ProductEditor;
