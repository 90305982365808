import React, { useState } from "react";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import Submenu from "./Submenu";
import { ChevronRightIcon } from "@heroicons/react/20/solid"
import { classNames } from "components/uiUtils";

import "./style.scss";

export default ({ menu }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const hasSubmenu = !_.isEmpty(menu.subMenus);

    const onMenuClicked = () => {
        if (!hasSubmenu) {
            navigate(menu.to);
        } else {
            setIsExpanded(!isExpanded);
        }
    }

    return (
        <li>
            <div>
                <div onClick={onMenuClicked}>
                    <label
                        className={classNames("hover:bg-gray-50 flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700",
                            // location.pathname.startsWith(menu?.to) && "bg-indigo-50")}
                        )}
                        htmlFor={classNames(!hasSubmenu && "toggle-sidenav")}
                    >
                        <div style={{ opacity: _.isEmpty(menu.subMenus) ? 0 : 1 }}>
                            <ChevronRightIcon className={classNames("h-5 w-5 text-gray-400 chevIcon", isExpanded && "rotate")} ></ChevronRightIcon>
                        </div>
                        {menu.title}
                    </label>
                </div>
                {
                    hasSubmenu && (
                        <ul className="mt-1 px-2" id="sub-menu-1">
                            {menu.subMenus.map((elm, i) => (
                                <div className={classNames("subMenu", isExpanded && "open")} key={i}>
                                    <Submenu key={i} to={elm.to}>{elm.title}</Submenu>
                                </div>
                            ))}
                        </ul>
                    )
                }
            </div>
        </li>
    )
}