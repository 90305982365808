import { InputFormat } from "components/Form/Input";
import { Column } from "components/Table/Simple";
import { formatBoolean, formatDate, formatDateTime, formatDollar } from "components/Table/Simple/presets";

export const columns: Column[] = [
    {
        field: "productId",
        label: "상품ID",
        visible: true,
    },
    {
        field: "seller",
        label: "판매자",
        visible: true,
        render: (value, field, row) => {
            return row.seller?.name
        }
    },
    {
        field: "category",
        label: "카테고리",
        visible: true,
        render: (value, field, row) => {
            return row.category?.name
        }
    },
    {
        field: "name",
        label: "상품명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "description",
        label: "설명",
        visible: true,
        creatable: true,
        editable: true,
    },
    {
        field: "price",
        label: "가격",
        visible: true,
        creatable: true,
        editable: true,
        format: InputFormat.NUMBER,
        render: formatDollar,
        align: "right"
    },
    {
        field: "mainImage",
        label: "대표이미지",
        visible: false,
    },
    {
        field: "detail",
        label: "상세정보",
        visible: false,
    },
    {
        field: "status",
        label: "상태",
        visible: true,
    },
    {
        field: "stockQuantity",
        label: "재고수량",
        visible: true,
        creatable: true,
        editable: true,
        align: "right"
    },
    {
        field: "isActive",
        label: "활성화",
        visible: true,
        render: formatBoolean,
        align: "center",
    },
    {
        field: "createdAt",
        label: "생성일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "updatedAt",
        label: "수정일",
        visible: true,
        render: formatDateTime
    },
    {
        field: "images",
        label: "이미지",
        visible: false,
    },
    {
        field: "options",
        label: "옵션",
        visible: false,
    }
]