import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useTable } from "../Core";
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { classNames } from "components/uiUtils";
import SpinnerMedium from "components/Spinner/Medium";
import MultiSelect from "components/Form/MultiSelect";
import styles from "./styles.module.scss";
import { InputFormat as InputFormatType } from "components/Form/Input";

export type TableData = {
    [key: string]: any;
}

export type Column = {
    field: string;
    label?: string;
    type?: string;
    format?: InputFormatType;
    visible?: boolean;
    creatable?: boolean;
    editable?: boolean;
    selectable?: {
        getOptions: (data) => { name: string, value: any }[];
    }
    textarea?: boolean;
    options?: any;
    width?: string;
    align?: "left" | "center" | "right";
    render?: (value: any, field: any, row: any) => any;
    mutate?: (value: any, field: any, row: any) => any;
    multiselect?: boolean;
}

export type RowInfo = {
    rowStyle?: (row: any) => React.CSSProperties;
    rowClassNames?: (row: any) => string;
}

export interface SimpleTableProps {
    data?: TableData[];
    columns?: Column[];
    rowInfo?: RowInfo;
    onRowClicked?: (rowIdx: number, row: any) => void;
    onSorted?: (fieldName: string, sortOrder: number) => void;
    rowClassNames?: { [rowIdx: string]: string };
    disableSort?: boolean;
    isStickyFirstCol?: boolean;
}

export const findColumnInfo = (columns: Column[], fieldName: string) => {
    return _.find(columns, (info) => info.field === fieldName);
}

const mutateTable = (data: TableData[], columns: Column[]) => {
    if (_.isNil(data)) {
        return [];
    }
    return data.map((row) => {
        let newRow = { ...row };
        columns.forEach((column) => {
            if (column.mutate) {
                newRow[column.field] = column.mutate(newRow[column.field], column.field, newRow);
            }
        })
        return newRow;
    })
}

const EMPTY_ARRAY = [];

const SimpleTable = ({
    data = EMPTY_ARRAY,
    columns = [],
    rowInfo = { rowStyle: (row) => ({} as React.CSSProperties) },
    onRowClicked = (rowIdx) => { },
    onSorted = (fieldName: string, sortOrder: number) => { },
    rowClassNames = {},
    disableSort = false,
    isStickyFirstCol = false
}: SimpleTableProps) => {
    // const mutatedTable = mutateTable(data, columns);
    const [mutatedData, setMutatedData] = useState(data);
    const table = useTable({ data: mutatedData, columns: columns });
    const tableRef = useRef(null);
    const [endIndex, setEndIndex] = useState(100);
    const [selectedField, setSelectedField] = useState("");
    const [filterMap, setFilterMap] = useState({});

    useEffect(() => {
        setMutatedData(mutateTable(data, columns));
    }, [data])

    useEffect(() => {
        // console.log("Use Effect Table Data")
        setEndIndex(100);
    }, [table.getData()])

    const onScroll = () => {
        // console.log("On Scroll")
        const element = tableRef.current;
        const scrollTop = element.scrollTop;
        // console.log("scrollTop", scrollTop)
        const scrollHeight = element.scrollHeight;
        // console.log("scrollHeight", scrollHeight)
        const clientHeight = element.clientHeight;
        // console.log("clientHeight", clientHeight)
        // when scroll reaches bottom
        if (scrollTop + clientHeight * 2 >= scrollHeight) {
            if (endIndex >= table.getData().length) {
                // console.log("Reached bottom endIndex", endIndex);
                return;
            }
            setEndIndex(endIndex + 100);
        }
    }

    if (_.isNil(table.getData())) {
        return (
            <SpinnerMedium></SpinnerMedium>
        )
    }

    const fieldsFromColumns = columns.map((column) => column.field);
    const fieldsFromTableData = table.getFields();
    const orderedFields = _.uniq([...fieldsFromColumns, ...fieldsFromTableData]).filter((fieldName: string) => findColumnInfo(columns, fieldName)?.visible !== false);

    // console.log("[Simple Table] sortOrder", table.getSortState(), selectedField)
    // console.log("[Simple Table] getData", table.getData())
    // if filter column exist add multiselect fore each column

    return (
        <div className="overflow-y-auto align-middle max-h-[80vh] bg-white pb-3" onScroll={onScroll} ref={tableRef}>
            <div className="flex space-x-2 bg-red-500">
                {columns.filter((column) => column.multiselect).map((column) => (
                    <div>
                        <MultiSelect
                            label={column.label}
                            items={
                                _.uniq(table.getData().map((row) => row[column.field]))
                                    .map((value, i) => ({ id: i, name: column.render?.(value, column.field, {}) ?? String(value) }))
                            }
                            onChange={(itemNames) => { setFilterMap({ ...filterMap, [column.field]: itemNames }) }}
                        />
                    </div>
                ))}
            </div>
            <table className={`border-separate border-spacing-[1px] shadow min-w-full bg-gray-300 ${styles.simpleTableStyles} ${isStickyFirstCol && styles.stickyFirstCol}`}>
                <thead className="relative sticky top-0 z-10 text-nowrap bg-white ">
                    <tr className="">
                        {orderedFields.map((fieldName: string, j) => {
                            const columnInfo = findColumnInfo(columns, fieldName);
                            const fieldLabel = !_.isNil(columnInfo) ? columnInfo?.label ?? fieldName : fieldName;
                            return (
                                <th className={classNames("py-0 whitespace-nowrap bg-opacity-25 text-left text-sm border-y-[1px] border-gray-300 px-4 py-2",
                                )}
                                    key={j}
                                    title={fieldName}
                                    onClick={() => {
                                        if (disableSort) {
                                            return;
                                        }
                                        table.sortData(fieldName);
                                        setSelectedField(fieldName);
                                        onSorted(fieldName, table.getSortState());
                                    }}>
                                    <div className="flex">{fieldLabel}
                                        {
                                            (table.getSortState() === 1 && selectedField === fieldName) ? <ChevronUpIcon className="size-5"></ChevronUpIcon> :
                                                (table.getSortState() === -1 && selectedField === fieldName) ? <ChevronDownIcon className="size-5"></ChevronDownIcon>
                                                    : ""

                                        }
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {table.getData().filter((row, i) => {
                        return _.every(filterMap, (itemNames: any, fieldName) => {
                            if (_.isEmpty(itemNames) || _.isNil(itemNames)) {
                                return true;
                            }
                            const columnInfo = findColumnInfo(columns, fieldName);
                            const value = row[fieldName];
                            const rendered = columnInfo?.render?.(value, fieldName, row) ?? value;
                            return itemNames.includes(rendered);
                        })
                    }).map((row, i) => (
                        <tr key={i} className={
                            classNames(
                                // "hover:ring hover:ring-[1px] hover:ring-indigo-500",
                                rowClassNames[i],
                                rowInfo?.rowClassNames?.(row)
                            )}
                            onClick={() => { onRowClicked(i, row) }}
                            style={rowInfo?.rowStyle?.(row)}
                        >
                            {orderedFields
                                .map((fieldName: string) => {
                                    const columnInfo = findColumnInfo(columns, fieldName);
                                    let value = row[fieldName];
                                    if (_.isArray(value)) {
                                        value = value.join(", ")
                                    }
                                    if (_.isObject(value)) {
                                        value = JSON.stringify(value);
                                    }
                                    return ({
                                        columnInfo, rendered: columnInfo?.render?.(value, fieldName, row) ?? value
                                    })
                                })
                                .map(({ columnInfo, rendered }, j) => {
                                    return (
                                        <td className="whitespace-nowrap text-sm text-gray-900 p-0"
                                            key={`${columnInfo?.field ?? i}_${j}`}
                                            align={columnInfo?.align}
                                        >
                                            <div className="py-1.5 px-4">
                                                {_.isNil(columnInfo) ? String(rendered) : rendered}
                                            </div>
                                        </td>)
                                })
                            }
                        </tr>)
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default SimpleTable;
