import React, { useState, useEffect } from "react";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import _ from "lodash";
import { findColumnInfo } from "components/Table/Simple";
import Select from "components/Form/Select";
import Textarea from "components/Form/Textarea";

const EditForm = ({ title = "데이터 수정", columns = [], columnNames = [], selectableColumnNames = [], data = {}, onCancel = () => { }, onSave = (data) => { } }) => {
    const [formData, setFormData] = useState(data);

    const onDataChanged = async (key, value) => {
        const newData = {
            ...formData,
            [key]: value
        }
        setFormData(newData);
    }

    return (
        <div className="flex flex-col space-y-1 min-w-[250px] w-[66vw] max-w-lg">
            <h1 className="text-xl font-semibold pb-1">{title}</h1>
            {columnNames.map((cn, idx) => {
                const columnInfo = findColumnInfo(columns, cn);
                const isSelectible = selectableColumnNames.includes(cn);
                if (isSelectible) {
                    const selectOptions = columnInfo.selectable.getOptions(formData);
                    let selectedItem = selectOptions.find((item) => item.value === formData[cn])
                    if (_.isNil(selectedItem)) {
                        selectedItem = selectOptions[0];
                        onDataChanged(cn, selectedItem.value);
                    }
                    return (
                        <div key={idx}>
                            <label>{findColumnInfo(columns, cn)?.label ?? cn}</label>
                            <Select items={selectOptions} selectedItem={selectedItem} onChange={(item) => { onDataChanged(cn, item.value) }}></Select>
                        </div>
                    )
                } else {
                    return (
                        <div key={idx}>
                            <label>{findColumnInfo(columns, cn)?.label ?? cn}</label>
                            {columnInfo?.textarea ?
                                <Textarea value={formData[cn]} onChange={(value) => { onDataChanged(cn, value) }} resizable={true}></Textarea> :
                                <Input value={formData[cn]} onChange={(value) => { onDataChanged(cn, value) }} format={columnInfo?.format}></Input>}
                        </div>
                    )
                }
            })}
            <div className="flex justify-end space-x-2 pt-1">
                <div>
                    <Button onButtonClicked={onCancel}>취소</Button>
                </div>
                <div>
                    <Button onButtonClicked={() => { onSave(formData) }}>저장</Button>
                </div>
            </div>
        </div>
    )
}

export default EditForm;