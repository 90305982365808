import React from "react"
import { classNames } from "components/uiUtils"

export default ({ onButtonClicked = () => { }, children, ...otherProps }) => {
    return (
        <button
            type="submit"
            className={classNames(
                "flex w-full h-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-nowrap items-center me-0 shadow-md",
                otherProps.disabled ? "bg-indigo-200 cursor-not-allowed" : "bg-indigo-600 hover:bg-indigo-500",
                otherProps.classNames
            )}
            onClick={onButtonClicked}
            disabled={otherProps.disabled}
        >
            {children}
        </button>
    )
}