import React, { useEffect, useState } from 'react'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from 'components/uiUtils';
import _, { set } from 'lodash';
import { getSha10length } from '../../uiUtils';
import SpinnerMedium from 'components/Spinner/Medium';
import InputUpperLabel from '../Input/Label/InputUpper';

export interface SelectItem {
    name: string;
    data?: any;
    avatar?: string;
}
// headlessui 2.0 version
const EXAMPLE_ITEMS: SelectItem[] = [
    {
        name: 'ETH',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
    },
    {
        name: 'USDT',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    },
    {
        name: 'USDC',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    },
    {
        name: 'XRP',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png',
    },
    {
        name: 'DOGE',
        avatar: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png',
    },
]

const NOT_SELECTED_TEXT = "Not Selected";

const EMPTY_ITEM: SelectItem = {
    name: NOT_SELECTED_TEXT,
}


export const getUidOfItems = (items: SelectItem[]) => {
    const names = items.filter((item: any) => !_.isNil(item)).map((item: any) => item.name);
    const stringNames = JSON.stringify(names);
    return getSha10length(stringNames);
}

const Select = ({ upperLabel = "", items = EXAMPLE_ITEMS, selectedItem = EMPTY_ITEM, disabled = false, onChange = (item) => { }, saveSelectedItems = false }) => {
    const [uid, setUid] = useState("");

    useEffect(() => {
        if (_.isNil(items)) {
            return;
        }
        const encodedNames = getUidOfItems(items);
        setUid(encodedNames);
        if (saveSelectedItems) {
            console.log("[Select] useEffect saveSelectedItems", encodedNames)
            const saved = localStorage.getItem(encodedNames);
            console.log("[Select] useEffect saveSelectedItems saved", saved)
            if (saved) {
                onChange(JSON.parse(saved));
                return;
            }
        }
    }, [items])

    const onItemChange = (item) => {
        console.log("[Select] onItemChange", item)
        if (saveSelectedItems) {
            if (uid === "") return;
            localStorage.setItem(uid, JSON.stringify(item));
        }
        onChange(item);
    }

    if (_.isNil(items)) {
        return (<SpinnerMedium />);
    }

    if (_.isNil(selectedItem)) {
        return (<SpinnerMedium />);
    }

    return (
        <div>
            {upperLabel && <InputUpperLabel>{upperLabel}</InputUpperLabel>}
            <Listbox value={selectedItem} onChange={(item) => { onItemChange(item) }}>
                <ListboxButton
                    disabled={disabled}
                    className={"relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"} // focus:ring-2 focus:ring-indigo-500 
                >
                    <span className="flex items-center">
                        {selectedItem?.avatar &&
                            <div className="h-5 w-5 mr-2">
                                <img src={selectedItem?.avatar} alt="" className="flex-shrink-0 rounded-full" />
                            </div>
                        }
                        <span className="block truncate">{selectedItem?.name ?? NOT_SELECTED_TEXT}</span>
                    </span>
                    <div className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                </ListboxButton>
                <Transition leave="transition ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <ListboxOptions
                        anchor="bottom start"
                        className="z-[200] w-fit rounded-md border ring-1 ring-black ring-opacity-5 bg-white [--anchor-gap:4px] focus:outline-none sm:text-sm"
                    >
                        {items.map((item, idx) => {
                            if (_.isNil(item)) {
                                return <hr key={idx} className="border-t-1 border-gray-200" />
                            }
                            const isSelected = item?.name === selectedItem?.name;
                            return (
                                <ListboxOption
                                    key={item.name}
                                    value={item}
                                    className={classNames("group relative cursor-default select-none py-2 pl-3 pr-10 text-gray-900",
                                        "data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:text-white"
                                    )}
                                >
                                    <div className="flex items-center">
                                        {item.avatar &&
                                            <div className="h-5 w-5 mr-3">
                                                <img src={item.avatar} alt="" className="flex-shrink-0 rounded-full" />
                                            </div>
                                        }
                                        <div className={classNames("block truncate",
                                            // "group-data-[selected]:font-semibold" is selected
                                            isSelected ? "font-semibold" : ""
                                        )}>
                                            {item.name}
                                        </div>
                                    </div>
                                    <span
                                        className={classNames('absolute inset-y-0 right-0 flex items-center pr-4',
                                            // "group-data-[selected]:text-indigo-600",
                                            isSelected ? "text-indigo-600" : "text-gray-400"
                                        )}
                                    >
                                        <CheckIcon className={classNames("h-5 w-5 group-data-[focus]:text-white",
                                            // "group-data-[selected]:visible",
                                            isSelected ? "visible focus:text-white" : "invisible"
                                        )} />
                                    </span>
                                </ListboxOption>
                            )
                        })}
                    </ListboxOptions>
                </Transition>
            </Listbox >
        </div>
    )
}

export default Select;