export const validateNumberInput = (inputValue) => {
    const numberRegex = /^-?[0-9]*\.?[0-9]*$/; // 숫자와 소수점만 허용하는 정규식
    return numberRegex.test(inputValue);
};

export const validatePhoneInput = (inputValue) => {
    const phoneRegex = /^[0-9]*$/; // 숫자만 허용하는 정규식
    return phoneRegex.test(inputValue);
}

export const validateIdInput = (inputValue) => {
    const idRegex = /^[a-zA-Z0-9._]*$/;
    return idRegex.test(inputValue);
};
